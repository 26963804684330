import React, { useEffect } from "react";
import Header from "../../layouts/FrontHeader";
import Footer from "../../layouts/Footer";
import Marquee from "react-fast-marquee";
import { enquireScreen } from "enquire-js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Senate from "../../assets/images/senate.jpeg";
import { Input, Modal, Upload, message, Image } from 'antd';
import Walkway from "../../assets/images/walk_way-640x301.jpeg";
import Endpoint from "../../utils/endpoint";
import Absu_Logo from "../../assets/images/Abia_State_University_logo.svg.png";
import senateone from "../../assets/images/absu_senate.jpg";
import senatetwo from "../../assets/images/senate.jpeg";
import welcomepic from "../../assets/images/absu_welcome.jpeg";
import absugate from "../../assets/images/absu_gate.jpeg";
import studentcaro from "../../assets/images/absu_students_caro.jpeg";
import mountaincaro from "../../assets/images/absu_mountain_caro.jpg";
import vccaro from "../../assets/images/absu_vc_caro.webp";
import hero1 from "../../images/c2.jpg";
import award1 from "../../images/award-logo-1.svg";
import award2 from "../../images/award-logo-2.svg";
import award3 from "../../images/award-logo-3.svg";


let isMobile;


enquireScreen((b) => {
    isMobile = b;
});
const NewIndexPage = () => {

    const fetchPost = () => {
        // Endpoint.getPosts()
        Endpoint.getWpCategories()
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        fetchPost();
    }, []);
    //   require("../../../src/assets/css3/vendor/vendor.min.css");
    return (
        <>

        <iframe class="full-screen-preview__frame" src="https://absuweb.abiastateuniversity.edu.ng/" style={{height:"100vh", width:"100vw"}} name="preview-frame" frameborder="0" noresize="noresize" data-view="fullScreenPreview" allow="geolocation 'self'; autoplay 'self'">
        </iframe>
    
        </>
    );
};

export default NewIndexPage;
